@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:300,300i,400,400i,700,700i');
@fontFamily: 'Roboto', sans-serif;
@fontFamilyCondensed: 'Roboto Condensed', sans-serif;

@host: "https://cloud.iminence.com";
@hostImages: "@{host}/public/img";

@fontSizeM: 14px ;
@fontSizeL: ceil((@fontSizeM * 1.25));
@fontSizeXL: ceil((@fontSizeM * 1.40));
@fontSizeXXL: ceil((@fontSizeM * 1.55));
@fontSizeS: ceil((@fontSizeM * 0.85));
@fontSizeXS: ceil((@fontSizeM * 0.70));
@fontSizeXXS: ceil((@fontSizeM * 0.55));

@fontWeightL: lighter ;
@fontWeightN: normal ;
@fontWeightB: bold ;

@colorLink:#306294;
@colorLinkHover:#618cb7;
@colorText:#333;
@colorTitle:#4280bd;