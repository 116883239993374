html,
body,
main {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
}	
body {
    font-size: @fontSizeM;
    color: @colorText;
	font-family: @fontFamily;
}

b, 
strong {
    font-weight: @fontWeightB;
}

a {
	color: @colorLink;	
    &:hover,
    &:focus {
    	color: @colorLinkHover;	
        text-decoration: underline;
    }
}

h1 { color: @colorTitle;font-size: @fontSizeXL;font-weight: @fontWeightB; }
h2 { color: @colorTitle;font-size: @fontSizeL;font-weight: @fontWeightB; }
h3 { color: @colorTitle;font-size: @fontSizeM;font-weight: @fontWeightB; }
h4 { color: @colorTitle;font-size: @fontSizeS;font-weight: @fontWeightB; }
h5 { color: @colorTitle;font-size: @fontSizeXS;font-weight: @fontWeightB; }

.hide {
    display: none;
    opacity: 0;
}

main {
    >header {           
        >section {
        	height: 51px;
        	>nav.navbar {
        	    background-color: #0f81b3;
    			.navbar-brand {
    				color: #f7fbfc;
    			}
        		ul.navbar-nav {
        		    >li.nav-item {
        		        >a.nav-link {
        		            color: #f7fbfc;
        		        }
        		    }
        		}
        	}
        }
    }
    >section {
		position: fixed;
		top: 51px;
		bottom: 21px;
		left: 0;
		right: 0;
		>.cell-left {
			position: absolute;
			top: 0;
			left: 0;
			width: 400px;
			bottom: 0;
			overflow-y: scroll;
			background-color: #dcedf4;
			padding: 0.5rem;
			>.tree >ul.list-group >li.list-group-item {
			    padding: 0 !important;
			    >ul.list-group >li.list-group-item {
			        padding: 0 0 2px 0px !important;
			    }
			}
			ul.list-group {
				margin: 0;
				>li.list-group-item {
					border-radius: 0;
					border: none;
					background: none;
					padding: 0 0 2px 20px;
					>a {
						color: #333;
						display: block;
						cursor: pointer;
						text-decoration: none;
                        line-height: 20px;
                        font-size: 14px;
						>i.bi-folder,
						>i.bi-folder2-open {
							float: left;
							font-size: 16px;
							margin-right: 5px;
						}
						>i.bi-folder {
							display: block;
						}
						>i.bi-folder2-open {
							display: none;
						}
						>i.bi-arrow-return-right {
						    float: left;
						    margin-right: 5px;
						}
					}
					>.list-group {
						display:none;
					}
					&.selected {
						>a {
							color: #5294e1;
                            font-weight: bold;
							>i.bi-arrow-right-short {
							    display: block;
							}
						}
					}
					&.opened {
						>a {
							>i.bi-folder {
								display: none;
							}
							>i.bi-folder2-open {
								display: block;
							}
						}
						>.list-group {
							display:block;
						}
					}
					&:last-child {
						padding-bottom: 0px;
					}
				}
			}
			>.storage-info {
			    position: absolute;
			    left: 0;
			    right: 0;
			    bottom: 36px;
			    padding: 15px;
			    background-color: rgba(0, 0, 0, 10%);
			    >.progress {
			        background-color: #fff;
			        >.progress-bar {
			            background-color: #0f81b3;
			        }
			    }
			}
			>.add-folder {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
			    >button.btn {
                    width: 100%;
                    border-radius: 0;
                    background-color: #0f81b3;
                    color: #f7fbfc;
                    border: none;
			    }
			}
		}
		>.cell-right {
		    position: absolute;
		    top: 0;
		    right: 0;
		    left: 400px;
		    bottom: 0;
		    overflow-y: scroll;
		    background: #ffffff;
		    border-left: 1px solid #cdcdcd;
		    >.page-header {
                background-color: #f1f1f1;
			    border-bottom: 1px solid #cdcdcd;
                padding: 15px;
			    margin: 0 0 20px;
			    padding-bottom: 10px;
		    	>h1 {
				    margin: 0;
				    color: #2e343f;
				    >.float-end {
				        margin-top: -2px;
    				    a {
    				        width: 30px;
    				        height: 30px;
    				        border-radius: 0;
    				        border: none;   
    				        &.btn-primary {
                                background-color: #0f81b3;
                                color: #f7fbfc;  
    				        }
    				        &.disabled {
    				            pointer-events: none;
    				            background-color: #cdcdcd;
    				            color: #2e343f;
    				        }
    				    }
				    }
		    	}
		    }
		    >.folder-contents {
		        >table.table {
		            margin: 0 0 15px 0;
		            border-bottom: 1px solid #cdcdcd;
		            >thead,
		            >tbody {
		                >tr {
		                    >th,
		                    >td {
		                        padding: 0;
		                        >div {
		                            padding: 5px;
		                        }
		                        a {
		                            color: #000;
		                            text-decoration: none;  
		                            cursor: pointer;
		                        }
		                        &:first-child {
		                            padding-left: 15px;
		                        }
		                        &:last-child {
		                            padding-right: 15px;
		                        }
		                    }
		                }
		            }
		        }
		    }
		    >.w-50 {
		        width: 50%!important;
		        margin: 0 0 0 auto;
		        padding-right: 15px;
		        >.input-group {
		            >input.form-control {
		                
		            }
		            >button.btn {
		                
		            }
		        }
		    }
		}
		>.btn.btn-primary.btn-lg {
		    position: fixed;
		    bottom: 41px;
		    right: 40px;
		    z-index: 1;
		    width: 250px;
		    border-radius: 0;
		    display: none;
		}
    }
    >footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: #f1f1f1;
		border-top: 1px solid #cdcdcd;
		>section {
			text-align: center;
			padding: 2px;
			p {
				margin: 0;
				padding: 0;
				font-size: 12px;
				color: #97a0ab;	
			}
        }
    }
}

.modal {
    >.modal-dialog {
        >.modal-content {
            >.modal-header {
                background-color: #f0f0f0;
                >.modal-title {
                    font-size: @fontSizeL;
                    color: @colorTitle; 
                }
            }
            >ul.list-group {
                border-radius: 0;
            }
            >.modal-footer {
                background-color: #c6d5db;
                .btn {
                    font-size: 14px;
                    padding: 5px 10px;
                    text-transform: uppercase;
                    border-radius: 0;
                }
            }
        }
    }
}